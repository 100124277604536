<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-07-17 16:40:47
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-29 13:59:58
-->
<template>
  <a-modal
    :title="handle === 'add' ? '新增' : '编辑'"
    style="top: 8px"
    :width="600"
    v-model="visible"
    @ok="toSubmit"
    okText="提交"
    :maskClosable="false"
  >
    <a-form-model
      ref="form"
      :model="rowData"
      :rules="formRule"
      layout="horizontal"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }"
    >
      <!-- <a-form-model-item label="门店" prop="storeId">
        <a-select v-model="rowData.storeId" placeholder="请选择">
          <a-select-option :value="item.id" v-for="(item, index) in storeList" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item> -->
      <a-form-model-item label="门店" prop="storeName" v-if="handle == 'store_allocation'">
        <a-input v-model="rowData.storeName" disabled></a-input>
      </a-form-model-item>
      <a-form-model-item label="总配额" v-if="handle == 'store_allocation'">
        <span>{{sum}}</span>
        <span style="padding-left: 50px">已分配配额：{{useSum}}</span>
        <span style="padding-left: 50px">剩余配额：{{remainSum}}</span>
      </a-form-model-item>
      <a-form-model-item label="员工" prop="storeStaffName" v-if="handle == 'staff_allocation'">
        <a-input v-model="rowData.storeStaffName" disabled></a-input>
      </a-form-model-item>
      <a-form-model-item label="所属门店" v-if="handle == 'staff_allocation'">
        <span>{{rowData.storeName}}</span>
        <span style="padding-left: 50px">门店总配额：{{sum}}</span>
        <span style="padding-left: 50px">门店剩余配额：{{remainSum}}</span>
      </a-form-model-item>
      <a-form-model-item label="固定配额" prop="quotaAllocation">
        <a-input v-model="rowData.quotaAllocation" disabled placeholder="固定配额"></a-input>
      </a-form-model-item>
      <a-form-model-item label="调整配额至" prop="allocationNum">
        <a-input-number :min="rowData.number" v-model="rowData.allocationNum" placeholder="调整配额"></a-input-number>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { addDealerPreOrdersNum } from '../api/DealerPreOrdersNumApi'

export default {
  components: {},

  data() {
    return {
      visible: false,
      handle: 'add',
      rowData: {},
      sum: '',
      useSum: '',
      remainSum: '',
      // 表单验证
      formRule: {
        storeName: [{ required: true, message: '请输入门店', trigger: 'blur' }],
        storeStaffName: [{ required: true, message: '请输入员工', trigger: 'blur' }],
        allocationNum: [{ required: true, message: '请输入调整配额', trigger: 'blur' }],
        quotaAllocation: [{ required: true, message: '请输入固定配额', trigger: 'blur' }],
      },
      storeList: [],
    }
  },
  methods: {
    /**
     * 获取行数据
     */
    setRowData(row, handle) {
      this.handle = handle
      this.visible = true
      this.rowData = {}
      if (handle == 'staff_allocation') {
        console.info(row)
        this.rowData.storeStaffName = row.storeStaffName
        this.rowData.storeStaffId = row.storeStaffId
        this.rowData.storeName = row.storeName

        //门店配额数
        this.sum = row.storeOrderCount
        this.remainSum = row.storeSurplusCount
      }

      if (handle == 'store_allocation') {
        this.rowData.storeName = row.storeName
        this.rowData.storeId = row.storeId

        //经销商配额数
        this.sum = row.dealerOrderCount
        this.remainSum = row.dealerSurplusCount
        this.useSum = this.sum - this.remainSum
      }

      this.rowData.number = row.orderCount - row.surplusPreOrderCount
      this.rowData.quotaAllocation = row.orderCount

      // this.axios.get(`/api/base/store/dealerStore/list?pageSize=99999`).then((res) => {
      //   this.storeList = res.body.records
      // })
    },

    /**
     * 表单提交
     */
    toSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }

        if (this.handle == 'staff_allocation') {
          this.axios
            .post(`/api/base/quota/storePreOrdersNum/edit`, this.rowData)
            .then((res) => {
              this.$notification.success({ message: res.message })
              this.$emit('reload')
              this.visible = false
              this.rowData = {}
            })
            .catch((err) => {
              this.$notification.error({ message: res.message })
            })
        }

        if (this.handle == 'store_allocation') {
          this.axios
            .post(`/api/base/quota/dealerPreOrdersNum/updateStorePreOrderNumForDealer`, this.rowData)
            .then((res) => {
              this.$notification.success({ message: res.message })
              this.$emit('reload')
              this.visible = false
              this.rowData = {}
            })
            .catch((err) => {
              this.$notification.error({ message: res.message })
            })
        }
      })
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
</style>
